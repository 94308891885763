import React from 'react';
import { Carousel } from 'antd'; 
import { Link, graphql } from 'gatsby';
import SiteLayout from '@layouts/site-layout';


const our_partner = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3, 
        }
      },
      {
         breakpoint: 480,
         settings: {
           slidesToShow: 2, 
         }
       },
   ]
  };

const abut_stpap = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2, 
        }
      },
      {
         breakpoint: 480,
         settings: {
           slidesToShow: 1, 
         }
       },
   ]
  };
 

export default class InfoPage extends React.Component {
    render() {
        return (
            <SiteLayout>   

                <div className="top_margin_comn"> 
                        
                </div>

            </SiteLayout>
        )
    }
}
